import { FC } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAutobuyOptions } from '../../hooks/use-autobuy-options';
import { AutobuyState } from '../../stores/backend';
import { Checkbox } from '../Checkbox';
import { CommonButton } from '../CommonButton';
import {
    CommonModal,
    CommonModalContainer,
    CommonModalProps,
} from '../CommonModal';
import {
    CommonToggle,
    CommonToggleInput,
    CommonToggleSlider,
} from '../CommonToggle';
import { ModalLabel } from '../ModalLabel';
import { ModalTitle } from '../ModalTitle';
import {
    DecrementButtonWrapper,
    IncrementButtonWrapper,
    PlusMinusInput,
} from '../PlusMinusInput';

export type AutobuySubmissionProps = Omit<
    AutobuyState,
    'balance' | 'maxLossLimit' | 'gamesLimit'
>;

export type AutobuyModalProps = CommonModalProps &
    AutobuyState & {
        enableUnlimitedAutoBuy: boolean;
        enableMaxCardAutoBuy: boolean;
    } & {
        onActivate: (props: AutobuySubmissionProps) => void;
        formatCurrency: (value: number) => string;
    };

export const AutobuyModalContainer = styled(CommonModalContainer)`
    padding: 2.75rem 3.25rem;
    margin-bottom: 8rem;
`;

export const ModalTitleWrapper = styled(ModalTitle)`
    margin-bottom: 1.5rem;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 30.625rem;
`;

export const FlexItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.438rem;
`;

export const LabelFlexItem = styled(FlexItem)`
    flex-basis: 16rem;
`;

export const ValueFlexItem = styled(FlexItem)`
    flex-basis: 14.625rem;
    justify-content: space-between;
`;

export const Divider = styled.hr`
    position: absolute;
    width: 29.8125rem;
    left: 3.25rem;
    border-width: 0;
    height: 0.063rem;
    background-color: ${({
        theme: {
            autobuyModal: { color },
        },
    }) => color.divider};
`;

export const ActivationLabel = styled(ModalLabel)`
    font-size: 1.875rem;
    margin: 1.2rem 0 1.063rem;
`;

export const ActivationToggle = styled(CommonToggle)`
    width: 8.063rem;
    height: 3.875rem;
    margin-bottom: 0.938rem;

    ${CommonToggleSlider} {
        &:before {
            width: 3.5rem;
            height: 3.5rem;
            bottom: 0.188rem;
        }
    }

    ${CommonToggleInput} {
        &:checked + ${CommonToggleSlider}:before {
            -webkit-transform: translateX(3.938rem);
            -ms-transform: translateX(3.938rem);
            transform: translateX(3.938rem);
        }
    }
`;

export const ToggleLable = styled(ModalLabel)`
    font-size: 1.313rem;
`;

export const UnlimitedToggle = styled(ActivationToggle)`
    margin-bottom: 0;
`;

export const MaxCardsToggle = styled(ActivationToggle)`
    margin-bottom: 0;
`;

export const OKButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.563rem 0;
`;

export const AutobuyMessageCommon = styled.div`
    font-family: Signika-SemiBold;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${({
        theme: {
            autobuyModal: { color },
        },
    }) => color.message};
`;

export const AutobuyMessage1 = styled(AutobuyMessageCommon)`
    font-size: 1.313rem;
    line-height: normal;
    width: 65%;
`;

export const AutobuyMessage2 = styled(AutobuyMessageCommon)`
    font-size: 1.313rem;
    line-height: normal;
    width: 68%;
    margin-bottom: 0.938rem;
`;

export const AutobuyMessage3 = styled(AutobuyMessageCommon)`
    font-size: 1.125rem;
    line-height: 1.17;
    max-width: 31.5rem;
`;

export const activationStyle = ({ activation }: { activation: boolean }) => {
    if (!activation) {
        return;
    }

    return css`
        ${IncrementButtonWrapper}, ${DecrementButtonWrapper} {
            cursor: not-allowed;
            background-image: none;
            box-shadow: none;
            background-color: gray;
            opacity: 0.5;
        }
    `;
};

export const ActivationContainer = styled.div<{ activation: boolean }>`
    ${activationStyle};
`;

export const AutobuyModalMobile: FC<AutobuyModalProps> = ({
    enableMaxLoss,
    enableUnlimitedAutoBuy,
    enableMaxCardAutoBuy,
    onActivate,
    formatCurrency,
    ...props
}) => {
    const { t } = useTranslation();
    const {
        INC_TICKETS,
        INC_MAX_TICKET_PRICE,
        INC_MAX_LOSS,
        INC_GAMES,
        games,
        tickets,
        fixedPrice,
        anyPrice,
        maxTicketPrice,
        maxLoss,
        enableUnlimited,
        enableMaxCards,
        activation,
        incGames,
        decGames,
        incTickets,
        decTickets,
        incMaxTicketPrice,
        decMaxTicketPrice,
        incMaxLoss,
        decMaxLoss,
        handleCheckAnyPrice,
        handleCheckFixedPrice,
        handleCheckUnlimited,
        handleCheckMaxCards,
        handleCheckActivation,
    } = useAutobuyOptions(
        {
            maxLossLimit: props.maxLossLimit,
            gamesLimit: props.gamesLimit,
            games: props.games,
            tickets: props.tickets,
            maxTicketPrice: props.maxTicketPrice,
            maxLoss: props.maxLoss,
            fixedPrice: props.fixedPrice,
            anyPrice: props.anyPrice,
            enableUnlimited: props.enableUnlimited,
            enableMaxCards: props.enableMaxCards,
            activation: props.activation,
            enableMaxLoss,
        },
        onActivate
    );

    return (
        <CommonModal {...props} Container={AutobuyModalContainer}>
            <ActivationContainer activation={activation}>
                <ModalTitleWrapper title={t('modalAutoBuy.header')} />
                <FlexContainer>
                    <LabelFlexItem>
                        <ModalLabel label={t('modalAutoBuy.lblGames')} />
                    </LabelFlexItem>
                    <ValueFlexItem>
                        <PlusMinusInput
                            min={INC_GAMES.min}
                            max={INC_GAMES.max}
                            initialValue={games}
                            disabled={activation || enableUnlimited}
                            handleIncrement={activation ? undefined : incGames}
                            handleDecrement={activation ? undefined : decGames}
                        />
                    </ValueFlexItem>
                    {enableUnlimitedAutoBuy && (
                        <>
                            <LabelFlexItem></LabelFlexItem>
                            <ValueFlexItem>
                                <UnlimitedToggle
                                    checked={enableUnlimited}
                                    disabled={activation}
                                    onChange={handleCheckUnlimited}
                                />
                                <ToggleLable
                                    label={t('modalAutoBuy.lblUnlimited')}
                                />
                            </ValueFlexItem>
                        </>
                    )}
                    <LabelFlexItem>
                        <ModalLabel label={t('modalAutoBuy.lblTickets')} />
                    </LabelFlexItem>
                    <ValueFlexItem>
                        <PlusMinusInput
                            min={INC_TICKETS.min}
                            max={INC_TICKETS.max}
                            initialValue={tickets}
                            disabled={activation || enableMaxCards}
                            handleIncrement={
                                activation ? undefined : incTickets
                            }
                            handleDecrement={
                                activation ? undefined : decTickets
                            }
                        />
                    </ValueFlexItem>
                    {enableMaxCardAutoBuy && (
                        <>
                            <LabelFlexItem></LabelFlexItem>
                            <ValueFlexItem>
                                <UnlimitedToggle
                                    checked={enableMaxCards}
                                    disabled={activation}
                                    onChange={handleCheckMaxCards}
                                />
                                <ToggleLable
                                    label={t('modalAutoBuy.lblMaxCards')}
                                />
                            </ValueFlexItem>
                        </>
                    )}
                    <LabelFlexItem>
                        <ModalLabel label={t('modalAutoBuy.lblFixedPrice')} />
                    </LabelFlexItem>
                    <ValueFlexItem>
                        <Checkbox
                            checked={fixedPrice}
                            disabled={activation}
                            onChange={
                                activation ? undefined : handleCheckFixedPrice
                            }
                        />
                        <AutobuyMessage1>
                            {t('modalAutoBuy.infoFixedPrice')}
                        </AutobuyMessage1>
                    </ValueFlexItem>
                    <LabelFlexItem>
                        <ModalLabel label={t('modalAutoBuy.lblMaxPriceAny')} />
                    </LabelFlexItem>
                    <ValueFlexItem>
                        <Checkbox
                            checked={anyPrice}
                            disabled={activation}
                            onChange={
                                activation ? undefined : handleCheckAnyPrice
                            }
                        />
                    </ValueFlexItem>
                    {!anyPrice && (
                        <>
                            <LabelFlexItem>
                                <ModalLabel
                                    label={t('modalAutoBuy.lblMaxTickPrice')}
                                />
                            </LabelFlexItem>
                            <ValueFlexItem>
                                <PlusMinusInput
                                    min={formatCurrency(
                                        INC_MAX_TICKET_PRICE.min
                                    )}
                                    max={formatCurrency(
                                        INC_MAX_TICKET_PRICE.max
                                    )}
                                    initialValue={formatCurrency(
                                        maxTicketPrice
                                    )}
                                    disabled={activation}
                                    handleIncrement={
                                        activation
                                            ? undefined
                                            : incMaxTicketPrice
                                    }
                                    handleDecrement={
                                        activation
                                            ? undefined
                                            : decMaxTicketPrice
                                    }
                                />
                            </ValueFlexItem>
                        </>
                    )}
                    {enableMaxLoss && (
                        <>
                            <LabelFlexItem>
                                <ModalLabel
                                    label={t('modalAutoBuy.lblMaxLoss')}
                                />
                            </LabelFlexItem>
                            <ValueFlexItem>
                                <PlusMinusInput
                                    min={formatCurrency(INC_MAX_LOSS.min)}
                                    max={formatCurrency(INC_MAX_LOSS.max)}
                                    initialValue={formatCurrency(maxLoss)}
                                    disabled={activation}
                                    handleIncrement={
                                        activation ? undefined : incMaxLoss
                                    }
                                    handleDecrement={
                                        activation ? undefined : decMaxLoss
                                    }
                                />
                            </ValueFlexItem>
                        </>
                    )}
                </FlexContainer>
                <Divider />
                <ActivationLabel label={t('modalAutoBuy.lblActivate')} />
                <FlexContainer>
                    <ActivationToggle
                        checked={activation}
                        onChange={handleCheckActivation}
                    />
                    <AutobuyMessage2>
                        {t('modalAutoBuy.infoActivate')}
                    </AutobuyMessage2>
                </FlexContainer>
                <Divider />
                <OKButton>
                    <CommonButton handleClick={props.onClose}>
                        {t('modalAutoBuy.btnOk')}
                    </CommonButton>
                </OKButton>
                <AutobuyMessage3>{t('modalAutoBuy.footer')}</AutobuyMessage3>
            </ActivationContainer>
        </CommonModal>
    );
};
