import { FC } from 'react';
import { useHookstate } from '@hookstate/core';
import { PromoAdPanel as PromoAdPanelComponent } from '../../components/PromoAdPanel';
import {
    account,
    meta,
    next,
    screenConfig as screenConfigState,
} from '../../stores/backend';
import { useAccount } from '../../hooks/use-account';

export const PromoAdPanel: FC = () => {
    const $account = useHookstate(account);
    const $meta = useHookstate(meta);
    const $next = useHookstate(next);
    const { language } = useHookstate(screenConfigState);

    const countdownTimer = new Date($meta.timer.date.value);

    const { wagered } = useAccount($next, $meta, $account);

    return (
        <PromoAdPanelComponent
            adFileImageUrl={
                'https://casino.p6.dev.parlaygames.net/assets/SL/BHTB/img/icons/standard.jpg'
            }
            adFilename={'Promo Ad'}
            maxTickets={$next.game_cards_max.value}
            wagered={wagered}
            freeCards={$next.game_free_cards.value || 0}
            totalPromoCards={$next.game_total_promo_cards.value || 0}
            ticketsPurchased={
                Object.keys($next.player_cards.value || {}).length
            }
            countdownTimer={countdownTimer}
            currentLanguage={language.value}
        />
    );
};
