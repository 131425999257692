import { FC } from 'react';
import styled from 'styled-components';

export type GameStatusProps = {
    gameStatusHeader: string;
    gameStatusReason: string;
};

const GameStatusContainer = styled.div`
    width: calc(100% - 2.5rem);
    height: calc(100% - 2.5rem);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.gameStatus.background};
    border-radius: 1.213rem;
`;

const GameStatusInner = styled.div`
    display: block;
    text-align: center;

    & > p {
        margin: 0;
        padding: 0;
    }
`;

const GameStatusHeader = styled.p`
    font-family: Signika-Bold;
    font-size: 3rem;
    font-weight: 600;
`;

const GameStatusReason = styled.p`
    font-family: Signika-Medium;
    font-size: 1.5rem;
    line-height: 2;
`;

export const GameStatus: FC<GameStatusProps> = ({
    gameStatusHeader,
    gameStatusReason,
    ...props
}) => {
    return (
        <GameStatusContainer {...props}>
            <GameStatusInner>
                <GameStatusHeader>{gameStatusHeader}</GameStatusHeader>
                <GameStatusReason>{gameStatusReason}</GameStatusReason>
            </GameStatusInner>
        </GameStatusContainer>
    );
};
