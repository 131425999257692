import { FC, useCallback, useEffect, useMemo } from 'react';
import { useHookstate } from '@hookstate/core';
import { isMobile } from 'react-device-detect';
import { BuyModalMobile } from '../../components/BuyModal/mobile';
import { BuyModalDesktop } from '../../components/BuyModal/desktop';
import { Part } from '../../components/GamePart';
import { useBackendPayload } from '../../hooks/use-backend';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { useGamePart } from '../../hooks/use-game-part';
import { useGameType } from '../../hooks/use-game-type';
import { useJackpot } from '../../hooks/use-jackpot';
import { useSpecialGame } from '../../hooks/use-special-game';
import { useAccount } from '../../hooks/use-account';
import { useBoughtTickets } from '../../hooks/use-bought-tickets';
import { useQuickTicketPick } from '../../hooks/use-quick-ticket.pick';
import { useTicketPrice } from '../../hooks/use-ticket-price';
import { useCycleGameParts } from '../../hooks/use-cycle-game-parts';
import {
    account,
    meta,
    next,
    autoBuy,
    screenConfig,
} from '../../stores/backend/';
import { formatNumber } from '../../lib/format-number';

export const BuyModal: FC = () => {
    const $meta = useHookstate(meta);
    const $account = useHookstate(account);
    const $next = useHookstate(next);
    const $autoBuy = useHookstate(autoBuy);
    const {
        screenConfig: { display },
    } = useHookstate(screenConfig);

    const gameCurrency = $next.game_currency.value;

    const isOpen = $meta.modals.buy.value;

    const { backendPayload } = useBackendPayload();

    const formatCurrency = useFormatCurrency($next, $meta);

    const accountDetails = useAccount($next, $meta, $account);

    const gameType = useGameType($next);

    const special = useSpecialGame($next, $meta);

    const { jackpotName, jackpotPrize, jackpotCall } = useJackpot($next, $meta);

    const boughtTickets = useBoughtTickets($next);

    const quickPick = useQuickTicketPick($next, $meta);
    const { minimize } = quickPick;

    const ticketPrice = useTicketPrice($next);

    const { hasJackpot, allGameParts } = useGamePart($next, $meta);

    const parts = useMemo(
        () =>
            Object.values($next.game_parts.value || {}).map((part) => ({
                patterns: part.pat_code || [],
                matches: part.num_matches || 1,
            })),
        [$next.game_parts.value]
    );

    const size = useMemo(
        () => Object.keys(allGameParts).length,
        [allGameParts]
    );

    const index = useCycleGameParts(size);
    const prizeString = allGameParts[index]?.prizeString;

    const gamePrize =
        prizeString && prizeString.length > 1
            ? prizeString[0]
            : allGameParts[index]?.prize;

    const handleClose = useCallback(() => {
        meta.modals.buy.set(false);
        minimize();
    }, [minimize]);

    const handleBuy = useCallback(() => {
        backendPayload.buyTicket(quickPick.buying);
        handleClose();
    }, [handleClose, backendPayload, quickPick.buying]);

    const onGameInfo = useCallback(() => {
        $meta.modals.gameInfo.set(true);
    }, [$meta.modals.gameInfo]);

    const onAutoBuy = useCallback(() => {
        $meta.modals.autoBuy.set(true);
    }, [$meta.modals.autoBuy]);

    const onPickCards = useCallback(() => {
        $meta.modals.pickCards.set(true);
    }, [$meta.modals.pickCards]);

    useEffect(() => {
        if (isOpen) {
            backendPayload.getAccountStatus();
        }
    }, [backendPayload, isOpen]);

    const Component = isMobile ? BuyModalMobile : BuyModalDesktop;

    return (
        <Component
            {...quickPick}
            {...accountDetails}
            parts={parts}
            isOpen={isOpen}
            isDisabled={!$meta.enabled.buying.value}
            special={special}
            gameType={gameType}
            gameParts={size as Part}
            currentPart={(index + 1) as Part}
            gameTitle={$next.game_desc.value}
            gamePrize={formatCurrency(gamePrize, gameCurrency)}
            gameCards={formatNumber($next.game_cards.value || 0)}
            patternType={gameType}
            hasJackpot={hasJackpot}
            jackpotName={jackpotName}
            jackpotCall={jackpotCall}
            gamePlayers={formatNumber($next.game_players.value || 0)}
            patternName={allGameParts[index]?.desc}
            jackpotPrize={jackpotPrize}
            boughtFreeTickets={$next.game_free_cards.value || 0}
            boughtTickets={boughtTickets}
            totalPromoCards={$next.game_total_promo_cards.value || 0}
            ticketPrice={formatCurrency(ticketPrice, gameCurrency)}
            gameLinked={$next.game_linked?.value}
            gameContinued={$next.game_continued?.value}
            stripOnly={$next.game_strip_only.value}
            activation={$autoBuy.activation.value}
            showTotalTickets={display.showTotalTickets.value}
            onBuy={handleBuy}
            onGameInfo={onGameInfo}
            onClose={handleClose}
            onAutoBuy={onAutoBuy}
            onPickCards={onPickCards}
            countdownDate={new Date(meta.timer.date.value)}
            isGameLive={true} // TODO: Implement isGameLive value
        />
    );
};
