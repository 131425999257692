import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GameStatus as GameStatusComponent } from '../../components/GameStatus';

export const GameStatusPanel: FC = () => {
    const { t } = useTranslation();

    // TODO: Implement the logic for the game status
    const gameStatusResp = {
        status: 'GAME_MODE', // 'PAUSED', 'GAME_OVER'
        data: {
            header: 'PAUSED',
            reason: 'OFFLINE',
        },
    };

    const GAME_STATUS_HEADER: Record<string, string> = {
        PAUSED: t('gameStatus.paused'),
        GAME_OVER: t('gameStatus.gameOver'),
    };

    const getGameStatusHeader = GAME_STATUS_HEADER[gameStatusResp.data.header];

    const GAME_STATUS_REASON: Record<string, string> = {
        OFFLINE: t('gameStatus.offline'),
        TECHNICAL_ISSUE: t('gameStatus.technicalDifficulties'),
        VOID: t('gameStatus.voided'),
    };

    const getGameStatusReason = GAME_STATUS_REASON[gameStatusResp.data.reason];

    // TODO: Update the logic for the game status
    if (gameStatusResp.status === 'GAME_MODE') {
        return <></>;
    }

    return (
        <GameStatusComponent
            gameStatusHeader={getGameStatusHeader}
            gameStatusReason={getGameStatusReason}
        />
    );
};
