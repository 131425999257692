import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { useTheme } from 'styled-components';

export const LiveIndicatorContainer = styled.div<{ variant?: string }>`
    width: ${isMobile ? '2.375rem' : '3.375rem'};
    height: ${isMobile ? '1.825rem' : '2.625rem'};
`;

export const LiveIndicatorImage = styled.img`
    width: 100%;
    height: 100%;
    image-rendering: -webkit-optimize-contrast;
`;

export const LiveIndicator: FC = () => {
    const theme = useTheme();
    const { liveIndicator } = theme.images;

    return (
        <LiveIndicatorContainer>
            <LiveIndicatorImage src={liveIndicator} />
        </LiveIndicatorContainer>
    );
};
