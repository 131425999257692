import { FC } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CardId, CardIdText } from '../CardId';
import { CardToGoText, CardWinnerText } from '../CardTogo';
import { CardFooterContainer, CardRow as CardRow80 } from '../BingoCard80';
import { CardRow as CardRow90 } from '../BingoCard90';
import { CardNumber75Container, CardNumber75Text } from '../CardNumber75';
import { CardNumber90Container, CardNumber90Text } from '../CardNumber90';
import {
    CardNumber75Middle,
    CardRow as CardRow75,
    CardTextContainer,
    CardText,
} from '../BingoCard75';
import {
    CardNumber80Container,
    CardNumber80Text,
    ContainerStick,
} from '../CardNumber80';
import { BingoCardListProps, BINGO_CARD_COMPONENT } from './mobile';
import { GameStatusPanel } from '../../containers/GameStatusPanel';

const CARD_STYLES = {
    75: css`
        width: 18.5rem;
        height: 20rem;
    `,
    80: css`
        width: 18.5rem;
        height: 17.25rem;
        padding: 1.125rem 0.75rem 0.313rem;
    `,
    90: css`
        width: 28rem;
        height: 9rem;
        padding: 0.75rem 0.625rem 0.5rem;
    `,
};

export const BingoCardListContainer = styled.div<{
    cardType: 'stripped' | 'regular';
}>`
    position: absolute;
    left: 34.5rem;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 1.75rem;
    column-gap: 2rem;
    height: auto;
    overflow: auto;
    padding: 1.25rem;

    top: ${({ cardType }) => (cardType === 'stripped' ? '36rem' : '34.5rem')};

    &::-webkit-scrollbar-track {
        border-radius: 0.438rem;
        background-color: ${({ theme: { pickCardsModal } }) =>
            pickCardsModal.scroll.track};
    }

    &::-webkit-scrollbar {
        width: 0.625rem;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.75rem;
        background-color: ${({ theme: { pickCardsModal } }) =>
            pickCardsModal.scroll.thumb};
    }
`;

export const BingoCardListItem = styled.div<{
    gameType: number;
    cardTogoTextSize: string;
    cardWinnerTextSize: string;
}>`
    ${({ gameType }) => CARD_STYLES[gameType as keyof typeof CARD_STYLES]};

    /* 75BALL overrides */
    ${CardRow75} {
        grid-row-gap: 0.25rem;
    }

    ${CardTextContainer} {
        margin-bottom: 0.5rem;
    }

    ${CardText} {
        font-size: 2rem;
    }

    ${CardNumber75Middle} {
        width: 2.938rem;
        height: 2.625rem;
    }

    ${CardNumber75Container} {
        width: 3.313rem;
        height: 2.625rem;
    }

    ${CardNumber75Text} {
        font-size: 1.5rem;
    }

    /* 80BALL overrides */
    ${CardRow80} {
        grid-row-gap: 0.75rem;
    }

    ${CardNumber80Container} {
        width: 3.875rem;
        height: 2.75rem;
    }

    ${ContainerStick} {
        width: 0.5rem;
        height: 2.875rem;
        margin-right: -0.063rem;
    }

    ${CardNumber80Text} {
        font-size: 1.625rem;
    }

    ${CardFooterContainer} {
        margin: 0.375rem 0px;
    }

    /* 90BALL overrides */
    ${CardRow90} {
        grid-gap: 0.375rem;
    }

    ${CardNumber90Container} {
        width: 2.25rem;
        height: 2.25rem;
    }

    ${CardNumber90Text} {
        font-size: 1.5rem;
    }

    /* Shared overrides */
    ${CardIdText} {
        font-size: ${({ gameType }) => (gameType === 90 ? '0.75rem' : '1rem')};
    }

    ${CardToGoText} {
        font-size: ${({ cardTogoTextSize }) => cardTogoTextSize};
    }

    ${CardWinnerText} {
        font-size: ${({ cardWinnerTextSize }) => cardWinnerTextSize};
    }
`;

export const StripContainer = styled.div<{ active?: boolean }>`
    padding: 0.313rem 0.125rem 1rem;
    border-radius: 0.625rem;
    height: auto;
    opacity: ${({ active }) => (active ? 1 : 0.5)};
    pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
    background-color: ${({ theme }) => theme.cardList.background};
`;

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 1.25rem;
`;

export const StripId = styled(CardId)`
    text-align: center;
    margin-bottom: 0.313rem;
    width: auto;
`;

export const BingoCardListDesktop: FC<BingoCardListProps> = ({
    cards,
    called,
    matches,
    gameType,
    patterns,
    autoDaub,
    dauberColor,
    dauberStyle,
    hasFreeSpace,
    currentPart,
    cardTogoTextSize,
    cardWinnerTextSize,
    ...props
}) => {
    const { t } = useTranslation();
    const BingoCard = BINGO_CARD_COMPONENT[gameType];

    return (
        <BingoCardListContainer {...props} cardType={cards.type}>
            {cards.type === 'regular' &&
                cards.list.map(({ cardId, cardNumbers }) => (
                    <BingoCardListItem
                        as={BingoCard}
                        key={cardId}
                        cardId={cardId}
                        tiles={cardNumbers}
                        called={called}
                        matches={matches}
                        gameType={gameType}
                        patterns={patterns}
                        currentPart={currentPart}
                        autoDaub={autoDaub}
                        dauberColor={dauberColor}
                        dauberStyle={dauberStyle}
                        hasFreeSpace={hasFreeSpace}
                        cardTogoTextSize={t('baseCard.XToGo.sizeDesktop')}
                        cardWinnerTextSize={t('baseCard.winner.size90Desktop')}
                    />
                ))}
            {cards.type === 'stripped' &&
                cards.list.map((item) => (
                    <StripContainer key={item.stripId} active={true}>
                        <StripId>{`${t('modalPurchase.cardTypeStrips')} ${
                            item.stripId
                        }`}</StripId>
                        <CardsContainer>
                            {item.cards.map(({ cardId, cardNumbers }) => (
                                <BingoCardListItem
                                    as={BingoCard}
                                    key={cardId}
                                    cardId={cardId}
                                    tiles={cardNumbers}
                                    called={called}
                                    matches={matches}
                                    gameType={gameType}
                                    patterns={patterns}
                                    currentPart={currentPart}
                                    autoDaub={autoDaub}
                                    dauberColor={dauberColor}
                                    dauberStyle={dauberStyle}
                                    hasFreeSpace={hasFreeSpace}
                                    cardTogoTextSize={t(
                                        'baseCard.XToGo.sizeDesktop'
                                    )}
                                    cardWinnerTextSize={t(
                                        'baseCard.winner.size90Desktop'
                                    )}
                                    isStripped={
                                        cards.type === 'stripped' ? true : false
                                    }
                                />
                            ))}
                        </CardsContainer>
                    </StripContainer>
                ))}
            <GameStatusPanel />
        </BingoCardListContainer>
    );
};
