import { useHookstate } from '@hookstate/core';
import {
    ChangeEvent,
    FC,
    KeyboardEvent,
    useCallback,
    useRef,
    useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { EmojiSelectEvent } from '../../components/EmojiPicker';
import { useChatPayload } from '../../hooks/use-chat';
import { useOpenChat } from '../../hooks/use-open-chat';
import { useTicketPrice } from '../../hooks/use-ticket-price';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { current, next, meta, autoBuy } from '../../stores/backend';
import { chat, chatMeta } from '../../stores/chat';
import { FooterDesktop } from './desktop';
import { FooterMobile } from './mobile';
import { JackpotModal } from '../JackpotModal';
import { WinnerModal } from '../WinnerModal';

export type FooterProps = {
    size?: number;
    onToggleChat?: (size?: number) => void;
};

export const Footer: FC<FooterProps> = ({ size, onToggleChat }) => {
    const { t } = useTranslation();

    const [message, setMessage] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const inputReference = useRef<HTMLInputElement>(null);
    const endMessageReference = useRef<HTMLDivElement>(null);

    const { pathname } = useLocation();

    const $current = useHookstate(current);
    const $next = useHookstate(next);
    const $meta = useHookstate(meta);
    const $chat = useHookstate(chat);
    const $chatMeta = useHookstate(chatMeta);
    const $autoBuy = useHookstate(autoBuy);

    const ticketPrice = useTicketPrice($next);
    const formatCurrency = useFormatCurrency($next, $meta);
    const { chatPayload } = useChatPayload();

    const gameCurrency = $next.game_currency.value;
    const trialUser = $current.player_trial.value && $chat.trial.value;
    const $ticketPrice = ticketPrice
        ? formatCurrency(ticketPrice, gameCurrency)
        : t('buyBtn.buy.free');

    const onSelect = useCallback((e: EmojiSelectEvent) => {
        setMessage((prevMessag) => `${prevMessag}${e.native || e.shortcodes}`);
        inputReference.current?.focus();
    }, []);

    const onClick = useCallback((alias: string) => {
        setMessage((prevMessag) => `${prevMessag} @${alias} `);
        inputReference.current?.focus();
    }, []);

    const onFocus = useOpenChat($chat, $chatMeta, chatPayload);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    };

    const onEmoticon = () => {
        $chatMeta.emojiToggle.set(true);
        inputReference.current?.focus();
    };

    const onClickOutside = () => {
        setIsOpen(true);

        if (isOpen) {
            $chatMeta.emojiToggle.set(false);
            setIsOpen(false);
        }
    };

    const onToggleFooter = () => {
        $meta.footers.sideGames.set(!$meta.footers.sideGames.value);
    };

    const validateMessage = (): boolean => {
        return !message || trialUser;
    };

    const onSend = () => {
        if (validateMessage()) {
            setMessage('');
            return;
        }
        chatPayload.sendMessage(message.trim());
        setMessage('');
    };

    const onEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onSend();
        }
    };

    const onBuy = () => {
        $meta.modals.buy.set(true);
    };

    let placeholder = t('chatModal.typeHereToChat');

    if (!$chatMeta.initialized.value) {
        placeholder = t('chatModal.connecting');
    }

    if (!$chatMeta.chatEnabled.value) {
        placeholder = t('chatModal.enableInSettings');
    }

    if (
        $chatMeta.isBanned.value ||
        $chatMeta.isDisabled.value ||
        (trialUser && $chatMeta.chatToggle.value)
    ) {
        placeholder = t('chatModal.chatIsBlocked');
    }

    const Component = isMobile ? FooterMobile : FooterDesktop;

    return (
        <>
            <Component
                size={size}
                autoBuy={$autoBuy.activation.value}
                trialUser={trialUser}
                message={message}
                pathname={pathname}
                placeholder={placeholder}
                $chat={$chat}
                $chatMeta={$chatMeta}
                inputReference={inputReference}
                endMessageReference={endMessageReference}
                ticketPrice={$ticketPrice}
                onBuy={onBuy}
                onSend={onSend}
                onFocus={onFocus}
                onClick={onClick}
                onSelect={onSelect}
                onChange={onChange}
                onEnter={onEnter}
                onEmoticon={onEmoticon}
                onToggleChat={onToggleChat}
                onClickOutside={onClickOutside}
                onToggleFooter={onToggleFooter}
            />
            <JackpotModal inputRef={inputReference} />
            <WinnerModal inputRef={inputReference} />
        </>
    );
};
