import { useHookstate } from '@hookstate/core';
import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { AutobuyModalDesktop } from '../../components/AutobuyModal/desktop';
import {
    AutobuyModalMobile,
    AutobuySubmissionProps,
} from '../../components/AutobuyModal/mobile';
import {
    current,
    autoBuy,
    closeModal,
    getBalance,
    meta,
    screenConfig,
} from '../../stores/backend';

const onClose = () => closeModal('autoBuy');

const onActivate = (props: AutobuySubmissionProps) => {
    autoBuy.merge({
        ...props,
        balance: getBalance(),
    });
};

export const AutobuyModal: FC = () => {
    const $autoBuy = useHookstate(autoBuy);
    const $meta = useHookstate(meta);
    const $current = useHookstate(current);
    const $screenConfig = useHookstate(screenConfig);

    const {
        enableAutoBuyLossLimit,
        enableUnlimitedAutoBuy,
        enableMaxCardAutoBuy,
        autoBuyLossLimitAmount,
        autoBuyGameLimit,
    } = $screenConfig.screenConfig.display.value;

    const formatCurrency = useFormatCurrency($current, $meta);

    const Component = isMobile ? AutobuyModalMobile : AutobuyModalDesktop;

    return (
        <Component
            {...$autoBuy.value}
            enableMaxLoss={enableAutoBuyLossLimit}
            enableUnlimitedAutoBuy={enableUnlimitedAutoBuy}
            enableMaxCardAutoBuy={enableMaxCardAutoBuy}
            gamesLimit={autoBuyGameLimit}
            maxLossLimit={autoBuyLossLimitAmount}
            isOpen={$meta.modals.autoBuy.value}
            onClose={onClose}
            onActivate={onActivate}
            formatCurrency={formatCurrency}
        />
    );
};
