import { FC } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { useTranslation } from 'react-i18next';
import { CommonPanel } from '../CommonPanel';
import { CountdownTimer } from '../CountdownTimer';

export type PromoAdProps = {
    adFilename: string;
    adFileImageUrl: string;
    wagered: string;
    maxTickets: number;
    freeCards: number;
    ticketsPurchased: number;
    totalPromoCards: number;
    countdownTimer: Date;
    currentLanguage: string;
};

export type PromoAdPanelItemProps = {
    currentLanguage: string;
};

export const PromoAdPanelContainer = styled(CommonPanel)`
    font-family: Signika-Medium;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    height: auto;
    min-height: 12.125rem;
    padding: 0;
`;

export const PromoAdPanelImage = styled.img`
    width: 100%;
    height: 7rem;
    object-fit: cover;
`;

export const PromoAdPanelList = styled.div`
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    align-items: center;
    padding: 0 0.938rem;
    line-height: ${isMobile ? '1' : '1.3'};
`;

export const PromoAdPanelItem = styled.div<PromoAdPanelItemProps>`
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: ${isMobile ? '30px' : '52px'};

    &:nth-child(1),
    &:nth-child(2) {
        width: 30%;
    }
        
    &:nth-child(2) {
        padding-left: ${isMobile ? '1.25rem' : '0'}
        
        p:first-child {
            margin-right: ${isMobile ? '0.725rem' : '0'}
        }
    }

    &:nth-child(3) {
        width: 40%; {
        
        p:first-child {
            margin-right: ${(props) =>
                props.currentLanguage === 'pt'
                    ? '0'
                    : isMobile
                    ? '0.725rem'
                    : '1.875rem'};
            white-space: ${(props) =>
                props.currentLanguage === 'en' ? 'pre' : 'normal'}
        }
    }
`;

export const PromoAdPanelLabel = styled.p`
    padding: 0;
    margin: 0;
    word-break: break-word;
`;

export const PromoAdPanelCommonValue = styled.p`
    font-size: ${isMobile ? '2.8rem' : '3rem'};
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.promoAdPanel.color.commonValue};
    padding: 0;
    margin: 0;
    width: 70%;
`;

export const PromoAdPanelCountdownTimerValue = styled(CountdownTimer)`
    width: 82%;
    font-family: Signika-Medium;
    text-align: left;
    font-size: ${isMobile ? '2.8rem' : '3rem'};
    line-height: normal;
    color: ${(props) => props.theme.promoAdPanel.color.countdownTimerValue};
`;

export const PromoAdPanelMaxTicketsPurchased = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0;
`;

export const PromoAdPanel: FC<PromoAdProps> = ({
    adFilename,
    adFileImageUrl,
    wagered,
    maxTickets,
    freeCards,
    ticketsPurchased,
    totalPromoCards,
    countdownTimer,
    currentLanguage,
    ...props
}) => {
    const { t } = useTranslation();

    const $ticketsPurchased = ticketsPurchased - freeCards;
    const $isMaxTicketsPurchased = $ticketsPurchased === maxTickets;

    return (
        <PromoAdPanelContainer {...props} type="secondary">
            <PromoAdPanelImage src={adFileImageUrl} alt={adFilename} />
            <PromoAdPanelList>
                <PromoAdPanelItem currentLanguage={currentLanguage}>
                    <PromoAdPanelLabel>
                        {`${t('infoBox.nextGame')}:`}
                    </PromoAdPanelLabel>
                    <PromoAdPanelCountdownTimerValue to={countdownTimer} />
                </PromoAdPanelItem>
                <PromoAdPanelItem currentLanguage={currentLanguage}>
                    {$isMaxTicketsPurchased ? (
                        <>
                            <PromoAdPanelMaxTicketsPurchased>
                                {t('callboardMaxPurchaseBox.maxTicketsBought')}!
                            </PromoAdPanelMaxTicketsPurchased>
                        </>
                    ) : (
                        <>
                            <PromoAdPanelLabel>
                                {t('callboardPurchaseBox.ticketsPurchased')}
                            </PromoAdPanelLabel>
                            <PromoAdPanelCommonValue>
                                {$ticketsPurchased - totalPromoCards}
                            </PromoAdPanelCommonValue>
                        </>
                    )}
                </PromoAdPanelItem>
                <PromoAdPanelItem currentLanguage={currentLanguage}>
                    <PromoAdPanelLabel>
                        {t('callboardMaxPurchaseBox.wagered')}
                    </PromoAdPanelLabel>
                    <PromoAdPanelCommonValue>{wagered}</PromoAdPanelCommonValue>
                </PromoAdPanelItem>
            </PromoAdPanelList>
        </PromoAdPanelContainer>
    );
};
